import { styled } from 'stitches.config'
import { Text } from 'components/Text'

export const CtaFooterRoot = styled('section', {
  width: '100%',
  backgroundColor: '$accent-white',
})

export const CtaFooterContainer = styled('div', {
  maxWidth: '100%',
  margin: '0 auto',
  padding: '$7 $2 130px $2',
  backgroundColor: '$secondary-500',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',

  '@bp2': {
    maxWidth: 'none',
    padding: '$8 $6 $4 $6',
    borderRadius: '75px 75px 0px 0px',
  },

  variants: {
    hiddenSecondaryCta: {
      true: {
        paddingBottom: '90px',

        '@bp2': {
          paddingBottom: '$3',
        },
      },
    },
  },
})

export const Title = styled(Text, {
  textAlign: 'center',
  margin: 'auto',
  width: '100%',
  maxWidth: '700px',
  '@bp3': {
    maxWidth: '100%',
  },
})

export const Subtitle = styled(Text, {
  width: '100%',
  maxWidth: '310px',
  textAlign: 'center',
  marginTop: '$3',

  '@bp1': {
    maxWidth: '500px',
    marginTop: '$4',
  },
})

export const ButtonWrapper = styled('div', {
  marginTop: '$6',
  marginBottom: '$3',
  '@bp2': {
    marginTop: '$5',
    marginBottom: '$4',
  },
})

export const IconWrapper = styled('div', {
  position: 'absolute',
  bottom: '20px',

  '@bp2': {
    bottom: 'unset',
    top: '40px',
    right: '40px',
  },

  '@bp3': {
    top: '40px',
    right: '30px',
  },

  '@bp3_1': {
    top: '40px',
    right: '76px',
  },
})
