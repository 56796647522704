import React from 'react';
import { Icon } from 'components/Icon';
import { ColorTokens } from 'stitches.config';

interface SliderArrowProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  right?: boolean;
  color?: ColorTokens;
}

function SliderArrow(props: SliderArrowProps) {
  const { className, style, onClick, right = false, color } = props;
  return (
    <Icon
      color={color}
      size="8"
      name={`angle-${right ? 'right' : 'left'}`}
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      css={{zIndex: 2}}
    />
  );
}

export default SliderArrow;