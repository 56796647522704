import {
  CtaFooterRoot,
  CtaFooterContainer,
  ButtonWrapper,
  Title,
  Subtitle,
  IconWrapper,
} from './CtaFooter.styles'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'
import { useTranslation } from 'hooks/useTranslation'
import { Button } from 'components/Button'
import Link from 'next/link'
import { Icon } from 'components/Icon'
import { TranslationKeys } from 'locales/translationKeys'
import Trans from 'next-translate/Trans'

type CtaFooterProps = {
  title: TranslationKeys
  subtitle?: TranslationKeys
  hideSecondaryCta?: boolean
}

export const CtaFooter = (props: CtaFooterProps) => {
  const { title, subtitle, hideSecondaryCta = false } = props
  const { t } = useTranslation()

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <CtaFooterRoot>
      <CtaFooterContainer hiddenSecondaryCta={hideSecondaryCta}>
        <IconWrapper>
          <Icon
            onClick={scrollToTop}
            size="7"
            css={{ cursor: 'pointer' }}
            name="arrow-circle-up"
            label={t('common:cta-footer.scrollTop')}
            color="accent-white"
          />
        </IconWrapper>
        <Title font="display" size={{ '@initial': 'h3', '@bp2': 'h1' }} color="accent-white">
          <Trans i18nKey={title} components={{ br: <br /> }} />
        </Title>
        {subtitle && <Subtitle color="accent-white">{t(subtitle)}</Subtitle>}
        <ButtonWrapper>
          <Link passHref href="/meal-plan">
            <Button as="a" className="cta-footer cta">
              {t('common:cta-footer.CTA')}
            </Button>
          </Link>
        </ButtonWrapper>
        {!hideSecondaryCta && (
          <Stack>
            <Text color="accent-white">{t('common:cta-footer.or')}</Text>
            <Link href="/products">
              <Text css={{ cursor: 'pointer' }} color="accent-white">
                {t('common:cta-footer.secondaryCTA')}
              </Text>
            </Link>
          </Stack>
        )}
      </CtaFooterContainer>
    </CtaFooterRoot>
  )
}

CtaFooter.displayName = 'CtaFooter'
