import React, { ComponentProps } from 'react'
import { useTranslation } from 'hooks/useTranslation'
import { Stack } from 'components/Stack'
import { Icon } from 'components/Icon'
import { Link } from 'components/Link'
import { IconName } from 'components/Icon/iconNames'
import {
  FooterRoot,
  FooterNavigation,
  Logo,
  MainNav,
  SocialMediaNav,
  FooterPolicies,
  PoliciesNav,
  SocialMediaList,
  ContactUs,
  SecondaryNav,
  Copyright,
} from './Footer.styles'
import { Text } from 'components/Text'
import logo from '../../../public/assets/company-name.svg'
import { ColorTokens } from 'stitches.config'

const FOOTER_MAIN_NAV_SECTIONS = [
  { label: 'footer:nav.why', url: '/why-we-feed-raw' },
  { label: 'footer:nav.frozen-recipes', url: '/recipes' },
  { label: 'footer:nav.fd-recipes', url: '/recipes/freeze-dried-raw' },
  { label: 'footer:nav.raw-benefits', url: '/raw-benefits' },
  { label: 'footer:nav.raw-food-subscription', url: '/raw-food-subscription' },
] as const

const FOOTER_SECONDARY_NAV_SECTIONS = [
  { label: 'footer:nav.faqs', url: 'https://wefeedraw.gorgias.help/en-US' },
  { label: 'footer:nav.blog', url: '/blog' },
  { label: 'footer:nav.account', url: '/user' },
  {
    label: 'footer:nav.affiliate',
    url: 'https://www.shareasale.com/shareasale.cfm?merchantID=121566',
  },
] as const

const SOCIAL_MEDIA = [
  {
    label: 'footer:social.instagram',
    url: 'https://www.instagram.com/wefeedraw/',
    icon: 'instagram' as IconName,
  },
  {
    label: 'footer:social.facebook',
    url: 'https://www.facebook.com/wefeedraw/',
    icon: 'facebook' as IconName,
  },
  {
    label: 'footer:social.x',
    url: 'https://www.x.com/WeFeedRawFood',
    icon: 'x-social' as IconName,
  },
  {
    label: 'footer:social.tiktok',
    url: 'https://www.tiktok.com/@wefeedraw',
    icon: 'tiktok' as IconName,
  },
  {
    label: 'footer:social.youtube',
    url: 'https://youtube.com/channel/UCKE0vcmVUzfXlzudtoes19A',
    icon: 'youtube' as IconName,
  },
] as const

const POLICIES = [
  { label: 'footer:policies.terms', url: '/terms-and-conditions' },
  { label: 'footer:policies.privacy', url: '/privacy-policy' },
  { label: 'footer:policies.sitemap', url: '/sitemap' },
] as const

export type FooterOwnProps = ComponentProps<typeof FooterRoot> & {
  background?: ColorTokens
}

export const Footer = (props: FooterOwnProps) => {
  const { t } = useTranslation()

  return (
    <FooterRoot {...props} css={{ background: `$${props?.background}` }}>
      <FooterNavigation>
        <Stack direction="column" gap="4">
          <Logo src={logo} alt="We Feed Raw" loading="lazy" />
          <Stack direction="column" gap="4">
            <MainNav aria-label={t('footer:nav.aria')}>
              <ul>
                {FOOTER_MAIN_NAV_SECTIONS.map(({ label, url }, index) => (
                  <li key={index}>
                    <Link href={url}>{t(label as any)}</Link>
                  </li>
                ))}
              </ul>
            </MainNav>
            <SecondaryNav aria-label={t('footer:nav.secondary-aria')}>
              <ul>
                {FOOTER_SECONDARY_NAV_SECTIONS.map(({ label, url }, index) => (
                  <li key={index}>
                    <Link href={url}>{t(label as any)}</Link>
                  </li>
                ))}
              </ul>
            </SecondaryNav>
            <SocialMediaNav aria-label={t('footer:social.aria')}>
              <SocialMediaList>
                {SOCIAL_MEDIA.map(({ label, icon, url }, index) => (
                  <li key={index}>
                    <a
                      href={url}
                      aria-label={t(label)}
                      title={label}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Icon size={{ '@initial': '4', '@bp1': '3' }} name={icon} />
                    </a>
                  </li>
                ))}
              </SocialMediaList>
            </SocialMediaNav>
          </Stack>
        </Stack>
      </FooterNavigation>

      <FooterPolicies>
        <PoliciesNav aria-label={t('footer:policies.aria')}>
          <ul>
            {POLICIES.map(({ label, url }, index) => (
              <li key={index}>
                <Link href={url}>{t(label)}</Link>
              </li>
            ))}
          </ul>
        </PoliciesNav>

        <Copyright>
          <Text size="small" weight="bold" align="center">
            {t('footer:copyright')}
          </Text>
        </Copyright>

        <ContactUs>
          <Link href="https://wefeedraw.gorgias.help/en-US">
            {t('footer:nav.contact-us' as any)}
          </Link>
        </ContactUs>
      </FooterPolicies>
    </FooterRoot>
  )
}

Footer.displayName = 'Footer'
