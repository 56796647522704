import {
  ReviewsRoot,
  ReviewsContainer,
  ReviewCard,
  Title,
  ButtonWrapper,
  SliderContainer,
  ReviewContainer,
  Spacer,
} from './ReviewSlider.styles'
import { Text } from 'components/Text'
import { useTranslation } from 'hooks/useTranslation'
import { Button } from 'components/Button'
import Link from 'next/link'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import { sliderSettings } from 'fixtures/slider'
import { ColorTokens } from 'stitches.config'
import Trans from 'next-translate/Trans'
import { TranslationKeys } from 'locales/translationKeys'
import SliderArrow from './SliderArrow'

type ReviewSliderProps = {
  background: ColorTokens
  hideCTA?: boolean
  color: ColorTokens
  recipePage?: boolean
}

export const ReviewSlider = ({
  background,
  hideCTA = false,
  color,
  recipePage = false,
}: ReviewSliderProps) => {
  const { t } = useTranslation()

  type review = {
    author: TranslationKeys;
    comment: TranslationKeys;
  }

  const homeReviews: review[] = [
    {
      author: 'home:reviews.3.name',
      comment: 'home:reviews.3.message',
    },
    {
      author: 'home:reviews.1.name',
      comment: 'home:reviews.1.message',
    },
    {
      author: 'home:reviews.2.name',
      comment: 'home:reviews.2.message',
    },
    {
      author: 'home:reviews.4.name',
      comment: 'home:reviews.4.message',
    },
  ]

  const recipePageReviews: review[] = [
    {
      author: 'recipes-overview:reviews.1.name',
      comment: 'recipes-overview:reviews.1.message',
    },
    {
      author: 'recipes-overview:reviews.2.name',
      comment: 'recipes-overview:reviews.2.message',
    },
    {
      author: 'recipes-overview:reviews.3.name',
      comment: 'recipes-overview:reviews.3.message',
    },
  ]

  const reviews = recipePage ? recipePageReviews : homeReviews

  const sliderSettingsReviews = {
    ...sliderSettings,
    dots: true,
    arrows: true,
    nextArrow: <SliderArrow color={color} right />,
    prevArrow: <SliderArrow color={color} />,
  }

  return (
    <ReviewsRoot css={{ background: `$${background}` }}>
      <ReviewsContainer>
        <Title color={color} font="display" size="h1">
          {t('home:reviews.title')}
        </Title>
        <SliderContainer
          css={{
            '& .slick-dots li button:before': {
              border: `2px solid $${color}`,
            },

            '& .slick-dots li.slick-active button:before': {
              backgroundColor: `$${color}`,
            },
          }}
        >
          <Slider {...sliderSettingsReviews}>
            {reviews.map((review, index) => (
              <ReviewContainer key={index}>
                <ReviewCard
                  css={{
                    border: `1px solid $${color}`,
                  }}
                >
                  <Text size="body" transform="uppercase">
                    <Trans i18nKey={review.author} /> 
                  </Text>
                  <Text
                    css={{ marginTop: '12px', '@bp2': { marginTop: '$2' } }}
                    align="left"
                    size="body"
                  >
                    <Trans i18nKey={review.comment} />
                  </Text>
                </ReviewCard>
              </ReviewContainer>
            ))}
          </Slider>
        </SliderContainer>
        {!hideCTA ? (
          <ButtonWrapper>
            <Link href="/meal-plan" passHref>
              <Button as='a' className='cta'>{t('home:reviews.cta')}</Button>
            </Link>
          </ButtonWrapper>
        ) : (
          <Spacer />
        )}
      </ReviewsContainer>
    </ReviewsRoot>
  )
}

ReviewSlider.displayName = 'ReviewSlider'
